import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    dialog: []
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            state.dialog = [...state.dialog, action.payload];
        },
        removeLastMessage: (state) => {
            state.dialog.pop();
        },
        setDataChat: () => initialState
    }
});

export const {
    addMessage,
    removeLastMessage,
    setDataChat
} = chatSlice.actions;

export default chatSlice.reducer;
