import React from 'react';

import {HTTP_METHODS, REQUEST_TYPE} from '../const/HTTP';
import {chatRequest} from '../const/AXIOS';

const useRequest = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const axiosInstancesMap = new Map([
        [REQUEST_TYPE.CHAT, chatRequest]
    ]);

    const request = async (
        requestType = REQUEST_TYPE.CHAT,
        url,
        method = HTTP_METHODS.GET,
        data = {},
        headers = {}
    ) => {
        setError(false);
        setIsLoading(true);

        const axiosInstance = axiosInstancesMap.get(requestType);

        let reqHeaders = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${process.env.REACT_APP_SECRET_KEY}`,
            ...headers
        }

        try{
            const responce = await axiosInstance.request({
                method,
                url,
                headers: reqHeaders,
                data
            });

            setIsLoading(false);

            return responce;
        }
        catch(err){
            setError(true);
            setIsLoading(false);

            return "Error";
        }
    };

    return {
        isLoading,
        error,
        request
    };
}

export default useRequest;