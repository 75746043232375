import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Main from './pages/Main';

const App = () => {
    return(
        <Routes>
            <Route path="/">
                <Route index element={<Main />} />
                <Route path="*" element={<Navigate to={"/"} />} />
            </Route>
        </Routes>
    )
}

export default App;