import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HTTP_METHODS, REQUEST_TYPE } from '../const/HTTP';

import { addMessage, removeLastMessage } from '../redux/slices/chat';

import useRequest from './useRequest';

const useAi = () => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const {request} = useRequest();
    const dispatch = useDispatch();
    const {dialog} = useSelector(state => state.chat);

    const sendMessage = async (message) => {
        setError(false);

        const newRequestUser = {
            role: "user",
            content: message
        }

        dispatch(addMessage(newRequestUser));

        setIsLoading(true);

        const responce = await request(REQUEST_TYPE.CHAT, "/completions", HTTP_METHODS.POST, {
            model: "gpt-4",
	        messages: [...dialog, newRequestUser]
        });

        setIsLoading(false);

        if(responce === "Error"){
            setError(true);
            return;
        }

        dispatch(addMessage(responce.data.choices[0].message));
    }

    const regenerate = async () => {
        setError(false);
        setIsLoading(true);

        let dialogArray = [...dialog];
        dialogArray.pop();

        dispatch(removeLastMessage());

        const responce = await request(REQUEST_TYPE.CHAT, "/completions", HTTP_METHODS.POST, {
            model: "gpt-4",
	        messages: dialogArray
        });

        setIsLoading(false);

        if(responce === "Error"){
            setError(true);
            return;
        }

        dispatch(addMessage(responce.data.choices[0].message));
    }

    return{
        isLoading,
        error,
        sendMessage,
        regenerate
    }
}

export default useAi;